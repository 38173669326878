import { COLOURS } from '@core-ui/styles';
import { SxProps, Theme } from '@mui/material/styles';
import { TEXT_404_FONT_SIZE } from '@/pages/page404/consts';

export default (): Record<string, SxProps<Theme>> => ({
  blackLetter: {
    fontSize: `${TEXT_404_FONT_SIZE}px`,
  },
  rotatedLetter: {
    fontSize: `${TEXT_404_FONT_SIZE}px`,
    color: COLOURS.HUE_COCONUT.HUE_50,
    transform: 'rotate(-15deg)',
  },
});
