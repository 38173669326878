import { LogoIconOcean } from '@core-ui/icons';
import { COLOURS, HEIGHT_SIZE } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MobileBadges from '@/pages/mobile/MobileBadges';
import React from 'react';
import { FormattedMessage } from 'components/Intl';

const MobileRoot = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      margin="0 auto"
      height={`calc(100vh - (${HEIGHT_SIZE.headerHeight}px))`}
      maxWidth={320}
    >
      <Grid item container direction="column" gap={10}>
        <Grid item container justifyContent="center">
          <LogoIconOcean fill={COLOURS.HUE_COCONUT.HUE_195} width={155} height={30} />
        </Grid>

        <Grid item container direction="column" gap={3} justifyContent="center">
          <Typography align="center" fontSize="large" fontWeight="semiBold">
            <FormattedMessage id="mobile_page.text.title" />
          </Typography>

          <Typography align="center" color={COLOURS.HUE_COCONUT.HUE_153}>
            <FormattedMessage id="mobile_page.text.subtitle" />
          </Typography>
        </Grid>

        <Grid item marginTop={5}>
          <MobileBadges />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileRoot;
