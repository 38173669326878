import { COLOURS } from '@core-ui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DATE_FORMAT } from '@/app/consts/app';
import { convertValueToUTCDate, formatTime } from '@/app/utils/dates';
import { FileStorageExpiringEnum } from '@/generated';
import ItemIcon from '@/pages/documents/DocumentsList/components/ExpireListItem/ItemIcon';
import NodeMoreButton from '@/pages/documents/DocumentsList/components/NodeMoreButton';
import { FileTreeNode } from '@/pages/documents/types';
import React, { FC } from 'react';
import { FormattedMessage } from 'components/Intl';
import useStyles from './styles';

export interface IProps {
  node: FileTreeNode;
  isSelected: boolean;
  status: FileStorageExpiringEnum;
  onSelect: (node: FileTreeNode) => void;
}

const ExpireListItem: FC<IProps> = (props) => {
  const { node, isSelected, status, onSelect } = props;

  const styles = useStyles(props);

  const handleItemClick = () => {
    onSelect(node);
  };

  let subtitle =
    status === FileStorageExpiringEnum.EXPIRED ? (
      <FormattedMessage id="documents.document_expired" />
    ) : (
      <FormattedMessage id="documents.document_will_expire_soon" />
    );

  if (node?.data?.expires_at) {
    if (status === FileStorageExpiringEnum.EXPIRED) {
      subtitle = (
        <FormattedMessage
          id="documents.document_expired_at"
          values={{ date: formatTime(convertValueToUTCDate(node.data.expires_at), { dateFormat: DATE_FORMAT }) }}
        />
      );
    } else if (status === FileStorageExpiringEnum.EXPIRE_SOON) {
      subtitle = (
        <FormattedMessage
          id="documents.document_will_expire_on"
          values={{ date: formatTime(convertValueToUTCDate(node.data.expires_at), { dateFormat: DATE_FORMAT }) }}
        />
      );
    }
  }

  const textFontWeight = isSelected ? 'semiBold' : undefined;

  return (
    <Grid item container gap={3} sx={styles.container}>
      <Grid item container gap={3} sx={styles.contentContainer} onClick={handleItemClick}>
        <Grid item>
          <ItemIcon node={node} isSelected={isSelected} />
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <Typography
              fontWeight={textFontWeight}
              color={isSelected ? COLOURS.HUE_FIG.HUE_100 : 'primary'}
              sx={styles.text}
            >
              {node.text}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color={COLOURS.HUE_COCONUT.HUE_153}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container width="auto" height="100%" alignItems="center">
        <NodeMoreButton node={node} enableFileTreeFunctions />
      </Grid>
    </Grid>
  );
};

export default ExpireListItem;
